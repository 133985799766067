import React from "react";
import { useTranslation } from "react-i18next";

import icon1 from "../../assets/images/icons/icon-01.png";
import icon2 from "../../assets/images/icons/icon-02.png";
import icon3 from "../../assets/images/icons/icon-03.png";

const ServiceOne = () => {
  const { t } = useTranslation();

  const ServiceList = [
    {
      icon: icon1,
      title: t("sliderServices.development.title"),
      description: t("sliderServices.development.description"),
    },
    {
      icon: icon2,
      title: t("services.website_development.title"),
      description: t("services.website_development.description"),
    },
    {
      icon: icon3,
      title: t("services.marketing_reporting.title"),
      description: t("services.marketing_reporting.description"),
    },
  ];

  return (
    <React.Fragment>
      <div className="row">
        {ServiceList.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
            <div className="service service__style--1">
              <div className="icon">
                <img src={val.icon} alt={t("services.icon_alt_text")} />
              </div>
              <div className="content">
                <h4 className="title">{val.title}</h4>
                <p>{val.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default ServiceOne;
